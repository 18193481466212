import ResendConfirmationEmail from '@/components/signup/ResendConfirmationEmail.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
export default defineComponent({
  components: {
    SiteFooter: SiteFooter,
    SiteHeader: SiteHeader,
    ResendConfirmationEmail: ResendConfirmationEmail
  },
  head: function head() {
    return {
      title: 'Business - Signup - Mail Sent'
    };
  }
});